import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AlertCircle, X } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import './QrReaderPage.css';

import { OnResultFunction } from '@blackbox-vision/react-qr-reader/dist-types/types';
import CheckInButton from './CheckInButton';
import { LocalScanInput, LocalScanResult, useCodes } from './CodesProvider';
import EditStartNumberForm from './EditStartNumberForm';
import { EventContext } from './EventProvider';
import { ScanStatus } from './global';
import { validateCode } from './helpers';
import QrReader from './QrReader';
import ScanStatusIcon from './ScanStatusIcon';
import { Button, Container, Icon, PageLoader } from './UI';
import { useDate } from './useDate';
import useScanSound from './useScanSound';

const { useRollbar } = require('@rollbar/react');

export interface QrReaderPageProps {
  scannerId: string;
  readTestValue?: () => string | null;
}

const QrReaderPage = ({ scannerId, readTestValue }: QrReaderPageProps) => {
  const { t } = useTranslation();
  const { formatUTC } = useDate();
  const { handleScanCode } = useCodes();
  const event = useContext(EventContext);
  const playScanSound = useScanSound();
  const rollbar = useRollbar();

  const scanner = event.enabled_scanners.filter(({ id }) => id === scannerId)[0];

  // Load the QR reader after a user interaction, so that navigator.vibrate() will start working.
  const [started, setStarted] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [cameraError, setCameraError] = useState(false);

  /** Contains the text contents of the scanned QR code */
  const [scannedCode, setScannedCode] = useState<string | null>(null);
  const [scannedStartNumber, setScannedStartNumber] = useState<string | null>(null);

  /** Contains the scan result after it has been processed */
  const [result, setResult] = useState<LocalScanResult | null>(null);

  const [editingStartNumber, setEditingStartNumber] = useState(false);

  const valueRef = useRef<string | null>(null);

  /**
   * Validates scan (offline-first), and queues the scan simultaneously to send to the backend.
   */
  const handleScan = useCallback((value: string | null) => {
    if (scanner && value && valueRef.current !== value) {
      valueRef.current = value;

      const result = handleScanCode({
        scanner_id: scanner.id,
        value,
        scanned_at: formatUTC(new Date(Date.now())), // Use Date.now to be able to mock the time in tests.
      });

      setResult(result);
      playScanSound(result);
      toggleEditingStartNumber(scanner.edit_start_numbers && !!result.code && !result.code.start_number);
    }
  }, [handleScanCode, setResult, playScanSound, formatUTC, scanner]);


  /**
   * Must be a stale callback!
   */
  const handleRead = useCallback((
    value: string | null,
    error: Error | null | undefined,
  ) => {
    setLoaded(true);

    if (value) {
      setScannedCode(value);
    }

    if (error) {
      const canRetry = (
        // Exceptions that can occur during QR scanning and decoding.
        error.name === 'NotFoundException' ||
        error.name === 'ChecksumException' ||
        error.name === 'FormatException' ||
        // Exceptions from the ZXing library are minified to 1 character in the production build.
        // We want to ignore those exceptions, and report browser errors (e.g. when the user denies permission).
        error.name.length === 1
      );

      if (!canRetry) {
        setCameraError(true);

        rollbar.error(error);
      }
    }
  }, [rollbar]);

  /**
   * When not editing a start number, handle the scan here.
   * When editing a start number, pass the intermediate result down to EditStartNumberForm.
   */
  useEffect(() => {
    if (scannedCode && scannedCode !== result?.code?.value && scannedCode !== result?.code?.start_number) {
      if (!editingStartNumber) {
        handleScan(scannedCode);
      } else {
        setScannedStartNumber(scannedCode);
      }
    }
  }, [scannedCode, editingStartNumber, handleScan, result]);

  const handleCheckIn = (input: LocalScanInput): LocalScanResult | null => {
    const result = handleScanCode(input);

    setResult(result);

    return result;
  }

  const toggleEditingStartNumber = (value: boolean) => {
    setScannedCode(null);
    setScannedStartNumber(null);
    setEditingStartNumber(value);
  };

  const saveStartNumber = (input: LocalScanInput) => {
    const result = handleScanCode(input);
    setResult(result);
    toggleEditingStartNumber(false);
    playScanSound({ ...result, rescan: false });
  };

  const reset = () => {
    setCameraError(false);
    setScannedCode(null);
    setScannedStartNumber(null);
    setEditingStartNumber(false);
    setResult(null);
    valueRef.current = null;
  };

  useEffect(() => {
    reset();
  }, [scannerId]);

  // Mock the QR reader in tests when readTestValue is given.
  // readTestValue is called every 100ms and can return the value of a scanned QR code.
  useEffect(() => {
    if (readTestValue) {
      let timer: number;

      const detect = () => {
        timer = window.setTimeout(() => {
          const value = readTestValue();
          if (value) {
            handleRead(value, undefined);
          }
          detect();
        }, 100);
      };
      detect();

      return () => clearTimeout(timer);
    }
  }, [handleRead, readTestValue]);

  const onResult: OnResultFunction = useCallback((result, error) => {
    handleRead(result ? result.getText() : null, error);
  }, [handleRead]);

  const { getCodeById } = useCodes();

  const code = result?.code ? getCodeById(result.code.id) : null;

  const { checkedIn, valid } = result && code ? validateCode(code, result.rescan) : {
    checkedIn: false,
    valid: false,
  };

  if (!scanner) {
    return <Redirect to={`/${event.id}`} />;
  }

  if (!started) {
    return (
        <div className="fixed inset-0 flex items-center p-4 pt-16">
          <Container>
            <Button onClick={() => setStarted(true)}>
              {t('start_scanning')}
            </Button>
          </Container>
        </div>
    )
  }

  return (
    <div>
      <div className="bg-black">
        <Container className="relative">
          {result?.code && (
            <div className={`qr-reader-flash ${checkedIn ? (valid ? 'bg-green-500' : 'bg-yellow-500') : 'bg-gray-400'}`} key={`${result.code.value}${result.code.start_number}`} />
          )}
          <QrReader
            onResult={onResult}
            scanDelay={500}
            constraints={{ facingMode: 'environment', aspectRatio: 1 }}
            className="qr-reader"
            ViewFinder={ViewFinder}
            key={scanner.id}
          />
        </Container>
      </div>

      {result && (
        <ScanResult
          result={result}
          scanner={scanner}
          editingStartNumber={editingStartNumber}
          toggleEditingStartNumber={toggleEditingStartNumber}
          startNumberScan={scannedStartNumber}
          onSaveStartNumber={saveStartNumber}
          onCheckIn={handleCheckIn}
          onClear={()=> reset()}
        />
      )}

      {!result && (
        <Container>
          {!loaded && (
            <div className="p-4 text-center text-yellow-500">
              <strong>{t('loading_scanner', { name: scanner.title })}</strong>
            </div>
          )}
          {loaded && !cameraError && (
            <div className="p-4 font-bold text-center text-gray-800">
              {t('scan_a_ticket')}
            </div>
          )}
          {loaded && cameraError && (
            <div className="p-4 text-center text-red-500">
              <Icon>
                <AlertCircle />
              </Icon>
              {' '}
              <strong>{t('could_not_load_camera')}</strong>
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

interface ScanResultProps {
  result: LocalScanResult;
  scanner: {
    id: string;
    edit_start_numbers: boolean;
  };
  editingStartNumber: boolean;
  toggleEditingStartNumber: (value: boolean) => void;
  startNumberScan: string | null;
  onSaveStartNumber: (input: LocalScanInput) => void;
  onCheckIn: (input: LocalScanInput) => LocalScanResult | null;
  onClear: () => void;
}

const ScanResult = ({ result, scanner, editingStartNumber, toggleEditingStartNumber, startNumberScan, onSaveStartNumber, onCheckIn, onClear }: ScanResultProps) => {
  const { t } = useTranslation();
  const { getCodeById, loading } = useCodes();

  const { rescan } = result;

  const getStatusClassName = (checkedIn: boolean, valid: boolean) => {
    if (!checkedIn && !valid) {
      return 'bg-gray-500';
    }

    if (checkedIn && !valid) {
      return 'bg-yellow-600';
    }

    return 'bg-green-600'
  }

  if (loading) {
    return <PageLoader />;
  }

  const code = result.code ? getCodeById(result.code.id) : null;

  if (code) {
    const { checkedIn, valid } = validateCode(code, rescan);

    return (
      <>
        <div className={`py-3 px-4 transition-colors text-white ${getStatusClassName(checkedIn, valid)}`}>
          <Container>
            <div className="flex items-center justify-between" style={{ lineHeight: 1.1 }}>
              <div className="mr-2">
                <ScanStatusIcon checkedIn={checkedIn} valid={valid} />
              </div>
              <div>
                <strong className="mr-2">
                  {code.title}
                </strong>
                <div className="space-x-2">
                  <small>
                    {checkedIn && valid && t('checked_in')}
                    {checkedIn && !valid && t('already_checked_in')}
                    {!checkedIn && t('checked_out')}
                  </small>
                  <code className="text-xs text-white-500 opacity-60">
                    {code.value}
                    </code>
                </div>
              </div>
              <button onClick={() => onClear()} className="p-2 ml-auto">
                <Icon className="text-white opacity-50">
                  <X />
                </Icon>
              </button>
            </div>
          </Container>
        </div>

        <Container>
          {editingStartNumber && (
            <EditStartNumberForm
              code={code}
              scanner={scanner}
              startNumberScan={startNumberScan}
              inputLabel={t('scan_or_enter_start_number')}
              onCancel={() => toggleEditingStartNumber(false)}
              onSave={onSaveStartNumber}
            />
          )}

          {!editingStartNumber && (
            <>
              {(code.start_number || scanner.edit_start_numbers) && (
                <>
                  <div className="p-4 space-y-4 sm:px-0">
                    {code.start_number && (
                        <div>
                          <Trans i18nKey="start_number_value" values={{ number: code.start_number }}>
                            <span className="inline-block px-1 py-0.25 text-sm text-gray-800 bg-gray-200 leading-sm font-bold" />
                          </Trans>
                        </div>
                    )}

                    {scanner.edit_start_numbers && (
                      <>
                        {!code.start_number && (
                          <div>
                            {t('no_start_number_assigned_yet')}
                          </div>
                        )}

                        <Button
                          onClick={() => toggleEditingStartNumber(true)}
                          background={code.start_number !== null ? 'gray' : 'purple'}
                        >
                          {code.start_number ? t('edit_start_number') : t('assign_start_number')}
                        </Button>
                      </>
                    )}
                  </div>
                  <hr />
                </>
              )}

              {code.response && (
                <>
                  <div className="p-4 space-y-4 text-md sm:px-0" dangerouslySetInnerHTML={{ __html: code.response }} />
                  <hr />
                </>
              )}

              <div className="p-4 space-y-4 sm:px-0">
                <CheckInButton code={code} scannerId={scanner.id} onToggle={onCheckIn} />
              </div>
            </>
          )}
        </Container>
      </>
    );
  }

  if (result.status === ScanStatus.Invalid) {
    return (
      <div className="relative flex items-center p-4 text-white bg-red-700">
        <Icon className="block p-2 mr-2 text-white bg-red-500 rounded-md">
          <AlertCircle />
        </Icon>
        <strong>{t('invalid_code')}</strong>
        <button onClick={() => onClear()} className="absolute p-2 right-2 top-2">
          <Icon className="text-white opacity-50">
            <X />
          </Icon>
        </button>
      </div>
    );
  }

  throw new Error(`Unknown scan status in ScanResult component: ${result.status}`);
};

const ViewFinder = () => (
  <div className="qr-view-finder"><div /></div>
);

export default QrReaderPage;
