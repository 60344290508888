import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import AppLayout from './AppLayout';
import CodePage from './CodePage';
import CodesPage from './CodesPage';
import EventPage from './EventPage';
import EventProvider, { eventConfig } from './EventProvider';
import LocaleProvider from './LocaleProvider';
import LoginPage from './LoginPage';
import Rollbar from './Rollbar';
import QrReaderPage from './QrReaderPage';
import CodesProvider from './CodesProvider';
import ScanQueueProvider from './ScanQueueProvider';
import OfflineStatusProvider from './OfflineStatusProvider';
import SessionProvider from './SessionProvider';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URL || "https://eventscanner.atleta.cc/graphql",
  cache: new InMemoryCache(),
  headers: eventConfig.headers,
});

const App = () => (
  <Rollbar>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <LocaleProvider>
          <SessionProvider>
            <OfflineStatusProvider>
              <Switch>
                <Route
                  path="/:eventId"
                  render={({ match: { params } }) => (
                    <Switch>
                      <Route>
                        <EventProvider {...params}>
                          <Switch>
                            <Route
                              path="/:eventId/scanners/:scannerId"
                              render={({ match: { params } }) => (
                                <ScanQueueProvider
                                  attemptInterval={5000}
                                  scannerId={params.scannerId}
                                  batchSize={100}
                                >
                                  <CodesProvider
                                    refreshInterval={15000}
                                    scannerId={params.scannerId}
                                    eventId={params.eventId}
                                  >
                                    <AppLayout>
                                      <Switch>
                                        <Route
                                          path="/:eventId/scanners/:scannerId/codes/:codeId/:action?"
                                          render={({ match: { params }}) => <CodePage {...params} />}
                                        />
                                        <Route
                                          path="/:eventId/scanners/:scannerId/codes"
                                          render={({ match: { params }}) => <CodesPage {...params} />}
                                        />
                                        <Route
                                          path="/:eventId/scanners/:scannerId/qr-reader"
                                          render={({ match: { params }}) => <QrReaderPage {...params} />}
                                        />
                                        <Route
                                          path="/:eventId/scanners/:scannerId"
                                          render={
                                            ({ match: { params }}) => (
                                              <Redirect to={`/${params.eventId}/scanners/${params.scannerId}/codes`} />
                                            )
                                          }
                                        />
                                      </Switch>
                                    </AppLayout>
                                  </CodesProvider>
                                </ScanQueueProvider>
                              )}
                            />
                            <Route path="/:eventId" component={EventPage} />
                          </Switch>
                        </EventProvider>
                      </Route>
                    </Switch>
                  )}
                />

                <Route render={() => <LoginPage />} />
              </Switch>
            </OfflineStatusProvider>
          </SessionProvider>
        </LocaleProvider>
      </BrowserRouter>
    </ApolloProvider>
  </Rollbar>
);

export default App;
