import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  // Register a callback to automatically apply updates.
  // When all new content has been precached, the service worker goes into a 'waiting' state.
  // Normally, the new service worker is activated when the app (or all browser tabs) are closed.
  // Now, we force activation by calling ServiceWorker.skipWaiting() and reloading the page.
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        const serviceWorker = event.target as ServiceWorker;

        if (serviceWorker.state === 'activated') {
          window.location.reload()
        }
      });

      // The service worker is configured to call skipWaiting() on receiving a SKIP_WAITING message.
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
